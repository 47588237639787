<template>
    <div>
        <div class="p-2">
            <div class="row justify-content-center">
                <div class="col-md-10">
                        <p class="text-center text-lg  font-semibold">REVIEW Transaski</p>
                        <button type="button" @click="$router.go(-1)" class="btn btn-sm btn-style10"><span class="typcn typcn-arrow-left"></span></button>
                    <hr>
                    <div class="shadow p-3 rounded-lg" v-if="notas.length>0">
                        <span class="float-right">{{notas[0].tanggal}}</span>
                        <p class="text-lg font-semibold">NOTA : {{$route.query.nota}}</p>
                        <p class="text-lg font-semibold float-right" v-if="keranjangs.length>0">
                            Jumlah Item : {{keranjangs.length}}
                        </p>
                        <p class="">Pelanggan : {{notas[0].nama}}</p>
                        <hr>
                        <p class="font-semibold">Catatan/Note : </p>
                        <div class="row">
                            <div class="col-6">
                                <div class="shadow p-3 rounded">
                                    <p class="font-semibold">Catatan Admin </p>
                                    {{notas[0].catatan_admin}}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="shadow p-3 rounded">
                                    <p class="font-semibold">Catatan Dokter </p>
                                    {{notas[0].catatan_dokter}}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="shadow p-3 rounded">
                                    <p class="font-semibold">Catatan Apoteker </p>
                                    {{notas[0].catatan_apoteker}}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="shadow p-3 rounded">
                                    <p class="font-semibold">Catatan Nurse </p>
                                    {{notas[0].catatan_nurse}}
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="shadow p-3 rounded-lg" v-if="keranjangs.length>0">
                        <p class="font-semibold text-lg p-4">List item : </p>
                        <div v-for="(item, index) in keranjangs" :key="index+'kerangajang'" class="p-3 shadow rounded my-2">
                            <p class="float-right font-bold text-lg">No : {{index+1}}</p>
                            <div class="row">
                                <div class="col-6">

                                    <table class="font-semibold">
                                        <tr>
                                            <td>Nama Produk</td>
                                            <td class="px-2">:</td>
                                            <td>
                                                {{item.nama_produk}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Harga</td>
                                            <td class="px-2">:</td>
                                            <td>
                                                Rp. {{formatRupiah(item.harga)}}
                                            </td>
                                        </tr>
                                       
                                    </table>
                                </div>
                                <div class="col-6">
                                    <table class="font-semibold">
                                         <tr>
                                            <td>Diskon</td>
                                            <td class="px-2">:</td>
                                            <td>
                                            {{item.diskon}} %
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jumlah</td>
                                            <td class="px-2">:</td>
                                            <td>{{item.jumlah}}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td class="px-2">:</td>
                                            <td>
                                                {{(parseInt(item.harga)-(parseInt(item.harga)*(parseInt(item.diskon)/100)))*parseInt(item.jumlah)}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <p></p>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import autophp from "@/plugins/_autophp.js"; // ganti source nya
let sdb = new autophp();
export default{
    data(){
        return{ 
            notas:[],
            keranjangs:[],
        }
    },
    methods: {
        formatRupiah(angka, prefix){ // prefix disini taruh Rp. 
            angka=angka.toString();
            var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split   		= number_string.split(','),
            sisa     		= split[0].length % 3,
            rupiah     		= split[0].substr(0, sisa),
            ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
            if(ribuan){
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
            }
            rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
            return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
        },
        getData(){
            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`select tb1.*,tb2.nama from app_kasir_transaksi_nota tb1 
            left join app_kasir_pelanggan tb2 on tb2.id=tb1.id_pelanggan
             where tb1.nota='${this.$route.query.nota}'`).then(res => {
                this.notas=res;
                this.$forceUpdate();
            });
            sdb.collection("app_kasir_keranjang",false).doc().select(`select tb1.*,tb2.nama_produk from app_kasir_keranjang tb1
            left join app_kasir_produk tb2 on tb2.id=tb1.id_produk
             where nota='${this.$route.query.nota}'`).then(res => {
                this.keranjangs=res;
                this.$forceUpdate();
            });
        }
    },
    mounted() {
        this.getData();
    },
}
</script>